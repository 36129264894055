.Body {
  color: #302A24;
  font-family: 'Hiragino Sans GB','Microsoft Yahei',sans-serif;
  text-align: left;
  font-size: 18px;
  width: 800px;
  img {
    max-width: 600px;
  }
  address,
  blockquote,
  dd, div,
  dl, dt, fieldset, form,
  frame, frameset,
  h1, h2, h3, h4,
  h5, h6, noframes,
  ol, p, ul,center,
  dir, hr, menu, pre{ display: block}

  li { display: list-item}

  head { display: none}
  table { display: table }

  tr { display: table-row}
  thead { display: table-header-group}
  tbody { display: table-row-group}
  tfoot { display: table-footer-group}
  col { display: table-column}
  colgroup { display: table-column-group}
  td, th { display: table-cell; }

  caption{ display: table-caption}
  th { font-weight: bolder; text-align: center}

  caption{ text-align: center}

  h1{ font-size: 2em; margin: .67em 0}
  h2{ font-size:1.5em; margin: .75em 0}
  h3{ font-size: 1.17em; margin: .83em 0}
  h4, p,
  blockquote, ul,
  fieldset, form,
  ol, dl, dir,
  menu { margin:1.12em 0}
  /* em是相对单位，1em就是一单位，浏览器默认的一单位是16px，
  可以通过 body{font-size:20px} 来修改一单位的值
  p的字体大小是1em，h1是2em，h2是1.5em，等等
  另外，与文字相关的距离值，最好用相对单位，例如 line-height:1.4; margin:.5em等等，这样做的好处就是当自定义了1em的绝对px时，line-height也会跟着变 */

  h5{ font-size: .83em; margin: 1.5em 0}
  h6{ font-size: .75em; margin: 1.67em 0}
  h1, h2, h3, h4,
  h5, h6, b,
  strong { font-weight: bolder}

  blockquote { margin-left: 40px; margin-right: 40px}
  i, cite, em,
  var, address { font-style: italic}

  pre, tt, code,
  kbd, samp { font-family: monospace}
  pre{ white-space: pre}
  button, textarea,
  input, object,
  select { display:inline-block; }

  big { font-size: 1.17em}
  small, sub, sup { font-size: .83em}
  sub{ vertical-align:sub}
  sup { vertical-align: super}
  table { border-spacing: 2px; }
  thead, tbody,
  tfoot { vertical-align: middle}
  td, th { vertical-align: inherit }
  s, strike, del { text-decoration: line-through}
  hr {border: 1px inset}

  ol, ul, dir,
  menu, dd { margin-left: 40px}
  ol {list-style-type: decimal}

  ol ul, ul ol,
  ul ul, ol ol { margin-top: 0;margin-bottom: 0}
  u, ins { text-decoration: underline}

  center{text-align: center}
  abbr, acronym { font-variant: small-caps; letter-spacing: 0.1em}
  :link, :visited { text-decoration: underline}
  :focus {outline: thindottedinvert}

  BDO[DIR="ltr"] { direction: ltr; unicode-bidi: bidi-override}
  BDO[DIR="rtl"] { direction: rtl; unicode-bidi: bidi-override}

  *[DIR="ltr"] { direction: ltr; unicode-bidi: embed}
  *[DIR="rtl"] { direction:rtl; unicode-bidi: embed}
  /* 这些标签或属性都不常用 */

  @media print{
  h1{ page-break-before:always}
  h1, h2, h3,
  h4, h5, h6{ page-break-after: avoid}
  ul, ol, dl { page-break-before: avoid}
  }
}

@media screen and (max-width: 980px) {
  .Body {
    width: 100%;
    img {
      max-width: 80vw;
    }
  }
}
