.ProtocalContainer{
  margin: 0 auto;
  @media screen and (min-width:1024px) {
    width: 1000px;
  }
  @media screen and (max-width:1024px) {
    width: 100%;
  }
  .ProtocalHeader{
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #cdcdcd;
    margin: 0 20px;
    padding: 20px 0;
    .Logo{
      height: 50px;
      width: 152px;
      background: url('https://tc-cdn.123u.com/official-website/logo/joy_u.png') no-repeat;
      background-size: 100% 100%;
    }
  }
  .Title{
    margin: 20px;
    font-size: 24px;
    text-align: center;
  }
  .Content{
    padding: 0 20px;
    word-break:break-all;
    * {
      text-align: justify;
      word-break:break-all;
    }
  }
}