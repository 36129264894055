.Plan{
  @media screen and (min-width:490px){
    margin:100px 0 50px 0;
    .PlanTitle{
      margin-bottom: 30px;
      text-align: center;
      font-size: 40px;
    }
    .PlanList{
      display: flex;
      max-width: 1200px;
      flex-wrap: wrap;
      margin:0 auto;
      justify-content: center;
      align-items: center;
      .PlanItem{
        width: 264px;
        height: 296px;
        margin: 0 10px 10px 10px;
      }
    }
  }
  @media screen and (max-width:490px){
    margin:50px 0 20px 0;
    .PlanTitle{
      text-align: center;
      margin-bottom: 30px;
      font-size:34px;
    }
    .PlanList{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .PlanItem{
        width:150px;
        height:160px;
        margin: 0 5px 10px 5px;
        img{
          max-width: 150px;
          max-height: 160px;
          width:auto;
          height: auto;
        }
      }
    }
  }
}
.Share{
  @media screen and (min-width:490px){
    background-color: #16161C;
    padding-top:50px;
    color:#FFF;
    .ShareTitle{
      text-align: center;
      font-size: 40px;
      font-weight: 400;
      line-height: 69px;
    }
    .SharerList{
      margin:0 auto;
      padding:70px 0;
      max-width: 1400px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items:center;
      .ShareItem{
        width:520px;
        height:360px;
        margin: 10px 70px 10px 70px;
        display: flex;
        .Left{
          margin-right:30px;
          .SharerPhoto{
            width:240px;
            height:300px;
            background: white;
            img{
              max-width: 240px;
              max-height: 300px;
              height: auto;
              width: auto;
            }
          }
          .SharerName{
            width:260px;
            margin-top:30px;
            text-align: center;
            font-size: 26px;
            color: #FFFFFF;
            line-height: 24px;
          }
        }
        .Right{
          .SharerDesc{
            height:180px;
            margin:20px 0;
            font-size: 16px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 24px;
          }
        }
      }
    }
  }
  @media screen and (max-width:490px){
    background-color: #16161C;
    padding-top:50px;
    color:#FFF;
    .ShareTitle{
      text-align: center;
      font-size: 34px;
      font-weight: 400;
      line-height: 69px;
    }
    .SharerList{
      margin:0 auto;
      padding:30px 0;
      max-width: 1400px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items:center;
      .ShareItem{
        margin: 10px 10px 10px 10px;
        display: flex;
        .Left{
          margin-right:10px;
          .SharerPhoto{
            img{
              height:55vw;
              width: 42vw;
            }
          }
          .SharerName{
            margin-top:15px;
            text-align: center;
            font-size: 16px;
            color: #FFFFFF;
            line-height: 24px;
          }
        }
        .Right{
          .SharerDesc{
            height:160px;
            font-size: 16px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 24px;
          }
        }
      }
    }
  }
}
.Train{
  @media screen and (min-width:490px){
    padding:70px;
    .TrainTitle{
      text-align: center;
      font-size: 40px;
      line-height: 69px;
    }
    .TrainDesc{
      text-align: center;
      margin:40px 0 40px 0;
    }
    .TrainDetail{
      margin: 0 auto;
      max-width:60vw;
      max-height:25vw;
      img{
        width: 60vw;
        height: 25vw;
      }
    }
  }
  @media screen and (max-width:490px){
    padding:30px 0 30px 0;
    .TrainTitle{
      text-align: center;
      font-size: 34px;
      line-height: 69px;
    }
    .TrainDesc{
      text-align: center;
      margin:20px 0 30px 0;
    }
    .TrainDetail{
      margin: 0 5vw 0 5vw;
      width: 90vw;
      height: 38vw;
      img{
        width: 90vw;
        height: 38vw;
      }
    }
  }
  
}
.Benefits{
  @media screen and (min-width:490px){
    background: #F4F4F4;
    padding:80px 20px 0px 20px;
    .BenefitsTitle{
      font-size: 40px;
      text-align: center;
    }
    .BenefitsDetail{
      margin:60px auto 0 auto;
      display: flex;
      max-width: 1000px;
      justify-content: center;
      .BenefitsList{
        width: 550px;
        line-height: 48px;
        .BenefitsItem{
          display: flex;
          align-items: center;
          .icon{
            margin-right: 20px;
            width: 20px;
            height: 20px;
            background: #FFDA6C;
            opacity: 0.4;
            border-radius: 50%;
          }
        }
      }
      .BenefitsImgWrap{
        width: 600px;
        height:550px;
        padding-left:20px;
        img{
          width:40vw;
          height: auto;
          max-width: 450px;
          max-height: 550px;
        }
      }
    }
  }
  .BenefitsDetail{
    margin:60px auto 0 auto;
    display: flex;
    max-width: 1024px;
    justify-content: center;
    .BenefitsList{
      width: 505px;
      line-height: 48px;
    }
    .BenefitsImgWrap{
      width: 600px;
      height:550px;
      padding-left:20px;
      img{
        width:40vw;
        height: auto;
        max-width: 450px;
        max-height: 550px;
      }
      @media screen and (max-width:1024px){
        display: none;
      }
    }
  }
}